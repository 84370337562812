import JobApplicationTableById from './modules/job-application/components/JobApplicationTableById';
import JobApplicationTable from './modules/job-application/components/JobApplicationTable';
import JobApplicationForm from './modules/job-application/components/JobApplicationForm';
import UpdatePassword from './modules/profile/components/UpdatePassword';
import { Dashboard, Profile } from './modules';
import Company from './modules/company/components/Company';
import WizardSteps from './modules/steps/components/WizardSteps';
import { Routes, Route, Navigate } from 'react-router-dom';
import Guard from '../../core/guards/private.guard';
import Layout from './Layout';
import React from 'react';
import JobPreview from './modules/job/components/JobPreview';
import JobForm from './modules/job/components/JobForm';
import JobTable from './modules/job/components/JobTable';

const PrivateRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<Guard />}>
        <Route path='account-activation' element={<WizardSteps />} />
        
        <Route element={<Layout />}>
          <Route path='profile' element={<Profile />} />
          <Route path='profile/update-password' element={<UpdatePassword />} />
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='company' element={<Company />} />
          <Route path='job' element={<JobTable action={true} />} />
          <Route path='job/create' element={<JobForm />} />
          <Route path='job/preview' element={<JobPreview />} />
          <Route path='job/:id' element={<JobForm />} />
          <Route path='job/:id/preview' element={<JobPreview />} />

          <Route path='job/:id/job-applications' element={<JobApplicationTableById />} />
          <Route path='job-applications' element={<JobApplicationTable />} />
          <Route path='job-applications/:id' element={<JobApplicationForm />} />

          <Route path='*' element={<Navigate to='dashboard' replace />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default PrivateRoutes;
