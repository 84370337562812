import { Steps, Button } from 'antd';
import { Header } from '../../../../../core/components';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import StepsProfile from '../components/stepsProfile';
import StepsCompanyProfile from '../components/stepsCompanyProfile';
import StepsClients from './stepsClients';
import StepsUsers from './stepsUsers';
import { ProfileService } from '../../profile/service/profile.service';
import { User } from '../../profile/model/user.model';
import { StepsClients as ModelClient } from '../model/steps-clients.model';

interface WizardStepsProps {
  onComplete?: () => void;
}

const WizardSteps: React.FC<WizardStepsProps> = ({ onComplete }) => {
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const [profile, setProfile] = useState<User | null>(null);
  const [companyId, setCompanyId] = useState<string | null>(null);
  const [clients, setClients] = useState<ModelClient[]>([]);
  const [isInitialSetup, setIsInitialSetup] = useState<boolean>(false);

  const refreshProfile = async () => {
    const updatedProfile = await ProfileService.Get();
    setProfile(updatedProfile);
    setCompanyId(updatedProfile.companyId || null);
  };

  useEffect(() => {
    const getProfile = async () => {
      try {
        const profile = await ProfileService.Get();
        if (!profile) throw new Error('Profile data is missing');
        setProfile(profile);
        setCompanyId(profile.companyId || null);
        setIsInitialSetup(profile.companySet === false);
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };
    getProfile();
  }, []);

  if (!profile) return null;

  const showAllSteps = isInitialSetup || profile.companySet === false;

  function isProfileComplete() {
    return profile?.firstname && profile?.lastname && profile?.email && profile?.phoneNumber;
  }

  function isStepValid() {
    if (!profile) return false;

    switch (current) {
      case 0:
        return isProfileComplete();
      case 1:
        return companyId && companyId !== '';
      case 2:
        return (clients?.length ?? 0) > 0;
      case 3:
        return true;
      default:
        return true;
    }
  }

  async function next() {
    if (isStepValid()) {
      if (current === 0 && profile) {
        try {
          await ProfileService.Patch({ ...profile, profileSet: false });
          refreshProfile();
        } catch (error) {
          console.error('Error updating profile:', error);
          return;
        }
      }
      setCurrent((prev) => prev + 1);
    }
  }

  function prev() {
    setCurrent((prev) => prev - 1);
  }

  const handleCompanyUpdated = (updatedCompanyId: string) => {
    setCompanyId(updatedCompanyId);
    refreshProfile();
  };

  async function goToDashboard() {
    if (profile && isProfileComplete()) {
      try {
        await ProfileService.Patch({ 
          ...profile, 
          profileSet: true,
          companySet: true 
        });
        await refreshProfile(); 
        if (onComplete) {
          onComplete();
        } else {
          navigate('/private/dashboard');
        }
      } catch (error) {
        console.error('Error updating profile:', error);
      }
    }
  }

  return (
    <div className='min-h-screen content-center'>
      <div className='p-6 md:p-0 w-full max-w-xl md:max-w-2xl lg:max-w-3xl xl:max-w-4xl mx-auto'>
        <Header title='Setup Your Account' buttons={[]} />
        <Steps current={current} className='mt-4 md:mt-10'>
          <Steps.Step title='User Profile' />
          {showAllSteps && (
            <>
              <Steps.Step title='Company Profile' />
              <Steps.Step title='Add Clients' />
              <Steps.Step title='Invite Users' />
            </>
          )}
        </Steps>
        <div>
          {current === 0 && <StepsProfile onProfileUpdated={refreshProfile} />}
          {showAllSteps && (
            <>
              {current === 1 && <StepsCompanyProfile onCompanyUpdated={handleCompanyUpdated} />}
              {current === 2 && <StepsClients onClientsChange={setClients} />}
              {current === 3 && <StepsUsers />}
            </>
          )}
          <div className='space-x-4'>
            {current > 0 && <Button onClick={prev}>Previous</Button>}
            {showAllSteps && current < 3 ? (
              <Button type='primary' onClick={next} disabled={!isStepValid()}>
                Next
              </Button>
            ) : (
              <Button
                type='primary'
                onClick={goToDashboard}
                disabled={!isProfileComplete()}>
                Dashboard
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WizardSteps;
