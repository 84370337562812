import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthStore } from '../../../../core/store/auth.store';
import { ProfileService } from '../../../private/modules/profile/service/profile.service';
import { User } from '../../../private/modules/profile/model/user.model';

const AuthHandler: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useAuthStore();
  
  const [profile, setProfile] = useState<User | null>(null);
  const [profileChecked, setProfileChecked] = useState(false);
  
  const checkInProgressRef = useRef(false); 

  useEffect(() => {
    const checkAuthAndProfile = async () => {
      if (checkInProgressRef.current) return;
      checkInProgressRef.current = true;

      try {
        const token = localStorage.getItem('token');
        
        const isPasswordSetupPage = location.pathname.includes('/public/user-registrations');
        if (!token && !isPasswordSetupPage && location.pathname !== '/login') {
          navigate('/login');
          return;
        }

        if (!token || !auth || !location.pathname.includes('/private/')) {
          return;
        }

        if (profileChecked && profile) return;

        const fetchedProfile: User = await ProfileService.Get();
        setProfile(fetchedProfile); 
        const needsAccountActivation = 
          !fetchedProfile.profileSet ||
          fetchedProfile.companySet === false ||
          !fetchedProfile.firstname ||
          !fetchedProfile.lastname ||
          !fetchedProfile.email ||
          !fetchedProfile.phoneNumber;

        if (needsAccountActivation && location.pathname !== '/private/account-activation') {
          navigate('/private/account-activation');
        } else if (!needsAccountActivation && location.pathname === '/private/account-activation') {
          navigate('/private/dashboard');
        }

        setProfileChecked(true);
      } catch (error) {
        console.error('Error checking profile:', error);
      } finally {
        checkInProgressRef.current = false;
      }
    };

    checkAuthAndProfile();
  }, [navigate, auth, location.pathname]); 

  return null;
};

export default AuthHandler;