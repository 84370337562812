import { JobApplication } from "../../../../../core/models/jobs/job-application.model";
import {
  IButton,
  ITableAction,
  ITableButton,
  ITableColumnType,
} from "../../../../../core/interfaces";
import {
  Table,
  TableConfigure,
  Header,
  Container,
} from "../../../../../core/components";
import ServerTable from "../../../../../core/components/Table/ServerTable";
import { Collator } from "../../../../../core/constants";
import { EyeOutlined, LinkOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import React from "react";

interface ICols {
  jobName: boolean;
}

interface ITablePagination {
  records: number;
  OnChange: (page: number, pageSize: number) => Promise<void>;
  OnShowSizeChange: (current: number, size: number) => Promise<void>;
}

interface Props {
  tableType: number;
  state: {
    from: number;
    jobId?: string;
  };
  cols: ICols;
  data: JobApplication[];
  pagination?: ITablePagination;
  GoBack?: IButton;
  dashboard?: boolean;
}

const TableComponent: React.FC<Props> = ({
  tableType,
  state,
  cols,
  data,
  pagination,
  GoBack,
  dashboard = false,
}) => {
  const navigate = useNavigate();

  const columns: ITableColumnType<JobApplication>[] = [
    ...(cols.jobName
      ? [
          {
            title: "Job Name",
            dataIndex: ["job", "name"],
            key: "jobname",
            filter: true,
            width: 300,
            sorter: (a: JobApplication, b: JobApplication) =>
              Collator.compare(a.job.name, b.job.name),
          },
        ]
      : []),
    {
      title: "Candidate",
      dataIndex: "fullname",
      key: "fullname",
      filter: true,
      width: 300,
      sorter: (a: JobApplication, b: JobApplication) =>
        Collator.compare(a.fullname, b.fullname),
    },
    {
      title: 'Recruiter',
      key: 'recruiter',
      filter: true,
      width: 200,
      sorter: (a: JobApplication, b: JobApplication) =>
        (a.job?.owner?.username || "").localeCompare(b.job?.owner?.username || ""),
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {record.job?.owner?.username || 'No recruiter'}
        </div>
      ),
    },
    {
      title: 'Client',
      key: 'client',
      filter: true,
      width: 200,
      sorter: (a: JobApplication, b: JobApplication) =>
        (a.job?.client?.name || "").localeCompare(b.job?.client?.name || ""),
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {record.job?.client?.name || 'No client'}
        </div>
      ),
    },
    {
      title: "Applied On (YYYY-MM-DD)",
      key: "createdDate",
      dataIndex: ["jobApplicationStatuses", "0", "statusTimestamp"],
      filter: true,
      width: 200,
      sorter: (a: JobApplication, b: JobApplication) =>
        new Date(a.jobApplicationStatuses[0]?.statusTimestamp).getTime() -
        new Date(b.jobApplicationStatuses[0]?.statusTimestamp).getTime(),
    },
    {
      title: "Status",
      filter: true,
      dataIndex: ["jobApplicationStatuses", "0", "statusName"],
      width: 200,
      sorter: (a: JobApplication, b: JobApplication) =>
        Collator.compare(
          a.jobApplicationStatuses[0].statusName,
          b.jobApplicationStatuses[0].statusName
        ),
    },
    {
      title: "Mobile",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 200,
      filter: true,
      sorter: (a: JobApplication, b: JobApplication) =>
        Collator.compare(a.phoneNumber, b.phoneNumber),
      render: (phoneNumber: string) => {
    
        return <div className="text-center">{phoneNumber}</div>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      filter: true,
      sorter: (a: JobApplication, b: JobApplication) =>
        Collator.compare(a.email, b.email),
      render: (email) => <div className="text-center">{email}</div>,
    },
  ];

  const buttons: ITableButton<JobApplication>[] = !dashboard
    ? [
        {
          title: "Profile",
          icon: {
            value: <LinkOutlined />,
            size: "text-lg",
          },
          color: "green",
          tone: 500,
          onClick: (item) => {
            window.open(item.websiteURL, "_blank");
          },
        },
      ]
    : [];

  const actions: ITableAction<JobApplication> = {
    visible: true,
    buttons: [
      ...buttons,
      {
        title: "View",
        icon: {
          value: <EyeOutlined />,
          size: "text-lg",
        },
        color: "blue",
        tone: 500,
        onClick: (item) =>
          navigate(`/private/job-applications/${item.id}`, { state }),
      },
    ],
    fixed: "none",
  };

  const { columns: enhancedColumns, data: enhancedData } =
    TableConfigure<JobApplication>({ columns, data, actions });

  return (
    <Container>
      <Header
        title="Job Applications"
        buttons={GoBack ? [GoBack] : []}
      />

      {tableType === 1 && pagination ? (
        <ServerTable<JobApplication>
          columns={enhancedColumns}
          dataSource={enhancedData}
          rowKey="id"
          records={pagination.records}
          OnChange={pagination.OnChange}
          OnShowSizeChange={pagination.OnShowSizeChange}
        />
      ) : (
        <Table<JobApplication>
          columns={enhancedColumns}
          dataSource={enhancedData}
          rowKey="id"
        />
      )}
    </Container>
  );
};

export default TableComponent;
