export class Users {
    constructor(
      public firstname: string = "",
      public lastname: string = "",
      public email: string = "",
      public phoneNumber: string = "",
      public active: boolean = true,
      public admin: boolean = false, 
      public id: string = "", 
    ) {}
  
    
  }