export class StepsUsers {
  constructor(
    public firstname: string = "",
    public lastname: string = "",
    public email: string = "",
    public admin: boolean = false, 
    public id: string = "", 
  ) {}

  
}