import {
  DeleteOutlined,
  EyeOutlined,
  PlusCircleOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Table, TableConfigure, Header, Container } from '../../../../../core/components';
import { ITableAction, ITableButton, ITableColumnType } from '../../../../../core/interfaces';
import { Job as ModelJob } from '../../../../../core/models/jobs/job.model';
import { UtilService } from '../../../../../core/services/util.service';
import { Collator } from '../../../../../core/constants';
import { JobService } from '../services/job.service';
import { useNavigate } from 'react-router-dom';

interface Props {
  action: boolean;
  ShowForm?: (item: ModelJob | null) => void;
}

const columns: ITableColumnType<ModelJob>[] = [
  {
    title: 'Job Name',
    dataIndex: 'name',
    key: 'name',
    filter: true,
    sorter: (a: ModelJob, b: ModelJob) => a.name.localeCompare(b.name),
  },
  {
    title: 'Recruiter',
    dataIndex: 'owner',
    key: 'recruiter',
    filter: true,
    sorter: (a: ModelJob, b: ModelJob) => a.owner?.username.localeCompare(b.owner?.username),
    render: (owner) => owner?.username || 'No recruiter'
  },
  {
    title: 'Client',
    dataIndex: 'client',
    key: 'client',
    filter: true,
    sorter: (a: ModelJob, b: ModelJob) => a.client?.name.localeCompare(b.client?.name),
    render: (client) => client?.name || 'No client'
  },
  {
    title: 'Posted On (YYYY-MM-DD)',
    dataIndex: 'createdDate',
    key: 'createdDate',
    filter: true,
    sorter: (a: ModelJob, b: ModelJob) => Collator.compare(a.subCategory, b.subCategory),
  },
  {
    title: 'Status',
    dataIndex: 'published',
    key: 'published',
    filter: true,
    sorter: (a: ModelJob, b: ModelJob) => {
      if (a.published === b.published) return 0;
      return a.published ? -1 : 1;
    },
    render: (published) => <span>{published ? 'Published' : 'Draft'}</span>,
  },
  {
    title: '# of Applications',
    dataIndex: 'jobApplicationsSize',
    key: 'jobApplicationsSize',
    filter: true,
    sorter: (a: ModelJob, b: ModelJob) => a.jobApplicationsSize - b.jobApplicationsSize,
  },
];

export interface JobPostTableRef {
  Get?: () => void;
}

const JobTable = forwardRef<JobPostTableRef, Props>(({ action, ShowForm }, ref) => {
  const [data, setData] = useState<ModelJob[]>([]);
  const navigate = useNavigate();

  const Get = useCallback(async () => {
    const result = await JobService.Get();
    if (result) {
      setData(result);
    }
  }, []);

  const Delete = async (item: ModelJob) => {
    await JobService.Delete(item.id);

    UtilService.Alert('Success!', 'success', 'The Job has been deleted.', undefined, async () => {
      await Get();
    });
  };

  useImperativeHandle(ref, () => ({
    Get,
  }));

  useEffect(() => {
    Get();
  }, [Get]);

  const GetButtons = (): ITableButton<ModelJob>[] => {
    if (!action) {
      return [];
    }

    return [
      {
        title: 'Delete',
        icon: {
          value: <DeleteOutlined />,
          size: 'text-lg',
        },
        color: 'red',
        tone: 500,
        onClick: async (item) => await Delete(item),
      },
      {
        title: 'Applications',
        icon: {
          value: <SolutionOutlined />,
          size: 'text-lg',
        },
        color: 'orange',
        tone: 500,
        onClick: (item) => {
          navigate(`/private/job/${item.id}/job-applications`);
        },
      },
    ];
  };

  const actions: ITableAction<ModelJob> = {
    visible: true,
    buttons: [
      ...GetButtons(),
      {
        title: 'View',
        icon: {
          value: <EyeOutlined />,
          size: 'text-lg',
        },
        color: 'blue',
        tone: 500,
        onClick: async (item) => navigate(`/private/job/${item.id}`),
      },
    ],
    fixed: 'none',
  };

  const { columns: enhancedColumns, data: enhancedData } = TableConfigure<ModelJob>({
    columns,
    data,
    actions: actions,
  });

  return (
    <Container>
      <Header
        title='Jobs'
        buttons={[
          {
            text: {
              value: 'Add Job',
              weight: 500,
              size: 'text-md',
            },
            icon: {
              value: <PlusCircleOutlined />,
              size: 'text-lg',
            },
            color: 'blue',
            tone: 500,
            btnClass: 'mr-1',
            onClick: () => navigate('/private/job/create'),
          },
        ]}
      />

      <div>
        <Table<ModelJob> columns={enhancedColumns} dataSource={enhancedData} rowKey='id' />
      </div>
    </Container>
  );
});

export default JobTable;
