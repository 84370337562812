import { useState, useEffect, useCallback } from 'react';
import { Table, Header, Container, Button, Input } from '../../../../../core/components';
import { ITableColumnType } from '../../../../../core/interfaces';
import { StepsClients as ModelClient } from '../model/steps-clients.model';
import { SaveOutlined, PlusOutlined } from '@ant-design/icons';
import { StepsClientsService } from '../services/steps-clients.service';
import { ProfileService } from '../../profile/service/profile.service';

interface StepsClientsProps {
  onClientsChange: React.Dispatch<React.SetStateAction<ModelClient[]>>;
}
const columns: ITableColumnType<ModelClient>[] = [
  { title: 'Name', dataIndex: 'name', key: 'name', filter: true },
  { title: 'Website', dataIndex: 'website', key: 'website', filter: true },
];

const StepsClients: React.FC<StepsClientsProps> = ({ onClientsChange }) => {
  const handleClientsChange = (clients: ModelClient[]) => {
    onClientsChange(clients);
  };

  const [data, setData] = useState<ModelClient[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [newClient, setNewClient] = useState<ModelClient>({ name: '', website: '', active: true });
  const [companyId, setCompanyId] = useState<string>('');

  useEffect(() => {
    const getProfile = async () => {
      try {
        const profile = await ProfileService.Get();
        setCompanyId(profile.companyId);
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };
    getProfile();
  }, []);

  useEffect(() => {
    const getClients = async (companyId: string) => {
      try {
        const clients = await StepsClientsService.Get(companyId);
        setData(clients);
        handleClientsChange(clients);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };
    if (companyId) getClients(companyId);
  }, [companyId]);

  const addNew = useCallback(() => setShowForm(true), []);

  const onChangeInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setNewClient((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }, []);

  const saveClient = async () => {
    if (!newClient.name || !newClient.website) {
      console.error('Both fields are required!');
      return;
    }

    if (!companyId) {
      console.error('Company ID is missing');
      return;
    }

    try {
      const clientData = new ModelClient(newClient.name, newClient.website, newClient.active);
      const response = await StepsClientsService.Post(companyId, clientData);

      if (response) {
        const currentData = Array.isArray(data) ? data : [];
        const newData = [...currentData, response];
        
        setData(newData);
        handleClientsChange(newData);
        
        setShowForm(false);
        setNewClient({ name: '', website: '', active: true });
      } else {
        console.error('Unexpected response from server:', response);
      }
    } catch (error) {
      console.error('Error saving client:', error);
    }
  };

  return (
    <Container>
      <Header title='Clients' buttons={[]} />

      <Table<ModelClient> columns={columns} dataSource={data} rowKey='id' />

      {showForm && (
        <div className='mt-4 p-4 border rounded bg-gray-100 space-y-6'>
          <div className='grid grid-cols-1 lg:grid-cols-2 gap-5'>
            <Input
              title='Name'
              type='text'
              id='clientName'
              name='name'
              placeholder='Enter client name'
              value={newClient.name}
              OnChange={onChangeInput}
            />
            <Input
              title='Website'
              type='text'
              id='clientWebsite'
              name='website'
              placeholder='Enter client website'
              value={newClient.website}
              OnChange={onChangeInput}
            />
          </div>
          <div className='flex justify-end mt-4'>
            <Button
              icon={{ value: <SaveOutlined />, size: 'text-lg' }}
              text={{ value: 'Save', weight: 400, size: 'text-md' }}
              color='green'
              tone={500}
              onClick={saveClient}
            />
          </div>
        </div>
      )}

      <div className='flex items-center justify-end mt-6'>
        {!showForm && (
          <Button
            icon={{ value: <PlusOutlined />, size: 'text-lg' }}
            text={{ value: 'Add New', weight: 400, size: 'text-md' }}
            color='blue'
            tone={500}
            onClick={addNew}
          />
        )}
      </div>
    </Container>
  );
};

export default StepsClients;
