import { ShortURL } from "./short-url.model";

export class Job {
  constructor(
    public createdDate: string = "1900-01-01",
    public createdBy: string = "",
    public id: string = "",
    public externalId: string = "",
    public name: string = "",
    public category: string = "",
    public subCategory: string = "",
    public description: string = "",
    public location: string = "",
    public image: string = "",
    public company: string = "",
    public jobProvider: string = "",
    public email: string = "",
    public hash: string = "",
    public postedDate: string = "1900-01-01",
    public featured: boolean = false,
    public published: boolean = false,
    public draft: boolean = false,
    public closed: boolean = false,
    public shortURL: ShortURL = new ShortURL(),
    public jobApplicationsSize: number = 0,
    public recruiter:string = "",
    public owner: Owner = { username: "" },
    public client: Client = { name: "" } 
  ) { }
}
export interface Owner {
  username: string;
}

export interface Client {
  name: string;
}