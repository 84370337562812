import { AccessTokenPayload } from '../../../../core/models/auth/access-token-payload.model';
import { UtilService } from '../../../../core/services/util.service';
import { Session } from '../../../../core/models/auth/session.model';
import { useAuthStore } from '../../../../core/store/auth.store';
import { AuthService } from '../../services/auth-login.service';
import React, { useEffect, useRef, useState } from 'react';
import { User } from '../../models/user.model';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const PasswordSetup: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setAuth } = useAuthStore();
  const [user, setUser] = useState<User>(new User());
  const [confirmPassword, setConfirmPassword] = useState('');
  const [id, setId] = useState('');
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const circleIndicator = <LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />;

  useEffect(() => {
    const code = searchParams.get('code');
    if (!code) {
      UtilService.Alert('Error', 'error', 'Invalid verification link');
      navigate('/login');
      return;
    }

    AuthService.GetInfo(code)
      .then((userData) => {
        if (!userData?.email || !userData?.id) {
          UtilService.Alert('Error', 'error', 'Invalid verification code');
          navigate('/login');
          return;
        }
        setUser((prevUser) => ({ ...prevUser, email: userData.email }));
        setId(userData.id);
      })
      .catch(() => navigate('/login'));
  }, [navigate, searchParams]);

  const HandleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const Validate = (user: User, callback: () => void) => {
    const errors: string[] = [];

    if (!user.password) errors.push('You must enter a password.');
    else if (user.password.length < 8) errors.push('Password must be at least 8 characters long.');

    if (!confirmPassword) errors.push('You must confirm your password.');
    else if (user.password !== confirmPassword) errors.push('Passwords do not match.');

    if (errors.length > 0) {
      UtilService.Alert('Important!', 'info', errors.join(' </br> '));
      return;
    }
    callback();
  };

  const SetupPassword = async () => {
    setLoading(true);
    const code = searchParams.get('code');
    if (!code || !id) {
      UtilService.Alert('Error', 'error', 'Invalid verification code or user ID');
      setLoading(false);
      return;
    }
    try {
      const item = await AuthService.SetupPassword(id, user.email, code, user.password);
      if (item) {
        const loginUser = new User();
        loginUser.username = user.email;
        loginUser.password = user.password;
        const loginResponse = await AuthService.Login(loginUser);
        if (loginResponse) {
          const payload: AccessTokenPayload = UtilService.DecodeJWT(loginResponse.accessToken);
          const session: Session = {
            session_state: payload.session_state,
            realm_access: payload.realm_access,
            resource_access: payload.resource_access,
            scope: payload.scope,
            sid: payload.sid,
            email_verified: payload.email_verified,
            name: payload.name,
            preferred_username: payload.preferred_username,
            given_name: payload.given_name,
            family_name: payload.family_name,
            email: payload.email,
          };
          setAuth(loginResponse, payload, session);
          navigate('/private/account-activation');
        }
      }
    } catch {
      UtilService.Alert('Error', 'error', 'Failed to set up password');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='flex min-h-screen flex-col justify-center px-6 py-12 lg:px-8'>
      <div className='max-w-[320px] w-full m-auto'>
        <div className='sm:mx-auto w-full'>
          <img
            className='mx-auto h-14 w-auto'
            src={require('../../../../assets/logo.png')}
            alt='Logo'
          />
          <h2 className='mt-4 text-center text-2xl/9 font-bold tracking-tight text-gray-900'>
            Setup Your Credentials
          </h2>
        </div>

        <div className='mt-10 sm:mx-auto w-full'>
          <div className='space-y-6'>
            <div>
              <label htmlFor='email' className='block text-sm/6 font-medium text-gray-900'>
                Username
              </label>
              <div className='mt-2'>
                <input
                  id='email'
                  name='email'
                  type='email'
                  readOnly
                  value={user.email}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 bg-gray-50 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6 px-3'
                />
              </div>
            </div>
            <div>
              <label htmlFor='password' className='block text-sm/6 font-medium text-gray-900'>
                New Password
              </label>
              <div className='mt-2'>
                <input
                  id='password'
                  name='password'
                  type='password'
                  autoComplete='new-password'
                  required
                  placeholder='Enter your password...'
                  ref={passwordRef}
                  value={user.password}
                  onChange={HandleInput}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && confirmPasswordRef?.current) {
                      confirmPasswordRef.current.focus();
                    }
                  }}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6 px-3'
                />
              </div>
            </div>
            <div>
              <div className='flex items-center justify-between'>
                <label
                  htmlFor='confirmPassword'
                  className='block text-sm/6 font-medium text-gray-900'>
                  Confirm Password
                </label>
              </div>
              <div className='mt-2'>
                <input
                  id='confirmPassword'
                  name='confirmPassword'
                  type='password'
                  autoComplete='new-password'
                  required
                  placeholder='Confirm your password...'
                  ref={confirmPasswordRef}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      SetupPassword();
                    }
                  }}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6 px-3'
                />
              </div>
            </div>

            <div>
              <button
                onClick={() => Validate(user, SetupPassword)}
                disabled={loading}
                className='flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
                {loading ? <Spin indicator={circleIndicator} /> : 'Sign In'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordSetup;
