import { BaseService } from '../../../../../core/services/base.service';
import { Company } from '../model/company-profile.model';

export class CompanyService {
  static async Get(id: string): Promise<Company> {
    return await BaseService.Get(`/companies/${id}`);
  }

  static async Post(company: Company): Promise<Company> {
    return await BaseService.Post('/companies', {
      name: company.name,
      website: company.website,
    });
  }

  static async Patch(id: string, company: Company): Promise<void> {
    await BaseService.Patch(`/companies/${id}`, {
      name: company.name,
      website: company.website,
    });
  }
}