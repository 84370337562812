import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import React from 'react';
import PublicRoutes from './public/public.routes';
import PrivateRoutes from './private/private.routes';
import AuthHandler from './public/components/auth-handler/AuthHandler';

const App: React.FC = () => (
  <Router>
    <AuthHandler />
    <Routes>
      <Route path="/*" element={<PublicRoutes />} />
      <Route path="/private/*" element={<PrivateRoutes />} />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  </Router>
);

export default App;