import { AccessToken } from '../../../core/models/auth/access-token.model';
import { UtilService } from '../../../core/services/util.service';
import { User } from '../models/user.model';

export class AuthService {
  private static readonly url: string = process.env.REACT_APP_API_URL!;
  private static readonly utils: UtilService = UtilService.GetInstance();

  static async Request<T>(
    method: string,
    endpoint: string,
    params: Record<string, unknown> = {},
    onError: (error: Error) => void
  ): Promise<T> {
    const url = `${this.url}${endpoint}`;
    let options: RequestInit = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    if (method === 'GET' || method === 'HEAD') {
      options.body = undefined;
    } else {
      options.body = JSON.stringify(params);
    }
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Something went wrong');
      }
      return data;
    } catch (error: unknown) {
      if (error instanceof Error) {
        onError(error);
      } else {
        onError(new Error('Unknown error occurred'));
      }
      throw error;
    }
  }

  static async Login(user: User): Promise<AccessToken> {
    const loginPayload = {
      username: user.username,
      password: user.password,
    };
    return await this.Request('POST', '/public/login', loginPayload, (error: Error) => {
      if (error.message.includes('UNAUTHORIZED')) {
        UtilService.Alert('Important!', 'info', 'Incorrect username or password');
      } else {
        UtilService.Alert('Important!', 'info', 'Internal Server Error');
      }
    });
  }

  static async GetInfo(verificationCode: string): Promise<{ email: string; id: string }> {
    try {
      const response = await this.Request<{ email: string; id: string }>(
        'GET',
        `/public/user-registrations?code=${verificationCode}`,
        {},
        (error: Error) => {
          console.error('API Error:', error.message);
          if (error.message.includes('NOT FOUND')) {
            UtilService.Alert('Error', 'error', 'Invalid verification code');
          } else {
            UtilService.Alert('Error', 'error', 'Internal Server Error');
          }
        }
      );
      return response;
    } catch (error) {
      console.error('Error Fetching Info:', error);
      throw error;
    }
  }
  static async SetupPassword(
    id: string,
    email: string,
    code: string,
    password: string
  ): Promise<AccessToken> {
    const payload = {
      email,
      verificationCode: code,
      password,
    };
    return await this.Request(
      'POST',
      `/public/user-registrations/${id}`,
      payload,
      (error: Error) => {
        console.error('API Error:', error.message);
        UtilService.Alert('Important!', 'info', 'Internal Server Error');
      }
    );
  }
}
