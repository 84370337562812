import { ExclamationCircleOutlined } from '@ant-design/icons';
import InputMask from 'react-input-mask';
import { IButton, IValidation } from '../interfaces';
import Button from './Button';
import React from 'react';
import { useEffect, useRef } from 'react';

interface Props {
  title?: string;
  placeholder?: string;
  type: string;
  id?: string;
  inputClass?: string;
  value?: string;
  name?: string;
  disabled?: boolean;
  simple?: boolean;
  simplestate?: boolean;
  buttons?: IButton[];
  OnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  validation?: IValidation | boolean;
  mask?: string;
  maskChar?: string;
}
const Input: React.FC<Props> = ({
  title,
  placeholder = '',
  type,
  id = '',
  inputClass = '',
  value = '',
  name = '',
  disabled,
  simple = false,
  simplestate = false,
  buttons,
  OnChange,
  validation,
  mask, maskChar = '_',
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const isValidationObject = typeof validation === 'object' && validation !== null;
  const validator = isValidationObject ? validation?.Validator?.() : validation === true;
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [value]);

  return (
    <div className={`${inputClass}`}>
      {(!simple || !simplestate) && title && (
        <div className='flex'>
          <span className='text-left block mb-2 text-sm font-medium text-gray-600'>{title}</span>

          {validation !== null &&
            (isValidationObject || (typeof validation === 'boolean' && validation)) && (
              <span className='text-left block mb-2 text-sm font-medium text-red-600 ms-1'>
                (*)
              </span>
            )}
        </div>
      )}

      <div className={`${buttons ? 'flex align-middle justify-center w-100' : ''}`}>
        {/* Conditionally render the masked input or regular input */}
        {mask ? (
          <InputMask
            mask={mask}
            maskChar={maskChar}
            value={value}
            onChange={OnChange}
            disabled={disabled}
          >
            {(inputProps) => (
              <input
                {...inputProps}
                ref={inputRef}
                className={`
                  bg-white border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1.5 px-2 focus:bg-white
                  ${isValidationObject && validator ? 'border-red-400 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-blue-500 focus:ring-blue-500'}
                `}
                type={type}
                id={id}
                name={name}
                placeholder={!simple || !simplestate ? placeholder : title}
              />
            )}
          </InputMask>
        ) : (
          <input
            ref={inputRef}
            className={`
              bg-white border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1.5 px-2 focus:bg-white
              ${isValidationObject && validator ? 'border-red-400 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-blue-500 focus:ring-blue-500'}
            `}
            type={type}
            id={id}
            placeholder={!simple || !simplestate ? placeholder : title}
            value={value}
            name={name}
            onChange={OnChange}
            disabled={disabled}
          />
        )}

        {buttons?.map((b, index) => (
          <Button
            key={index + 1}
            icon={b.icon}
            text={b.text}
            color={b.color}
            tone={b.tone}
            disabled={b.disabled}
            btnClass={`${b.btnClass} ms-2`}
            onClick={b.onClick}
            title={b.title}
          />
        ))}
      </div>
    </div>
  );
};

export default Input;
