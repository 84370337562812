export class User {
  constructor(
      public createdDate: string = "1900-01-01",
      public createdBy: string = "",
      public id: string = "",
      public externalId: string = "",
      public firstname: string = "",
      public lastname: string = "",
      public email: string = "",
      public type: string = "",
      public username: string = "",
      public phoneNumber: string = "",
      public hashCode: string = "",
      public whatsappNotifiable: boolean = false,
      public emailNotifiable: boolean = false,
      public fullname: string = "",
      public active: boolean = true,
      public companyId: string = "",
      public companySet: boolean =false,
      public profileSet: boolean = false
  ) {}
}
