import { useState, useEffect, useCallback } from 'react';
import { Table, Header, Container, Button, Input, CheckBox } from '../../../../../core/components';
import { ITableColumnType, ICheckboxOption } from '../../../../../core/interfaces';
import { SaveOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { ProfileService } from '../../profile/service/profile.service';
import { StepsUsersService } from '../services/steps-users.service';
import { StepsUsers as ModelUser } from '../model/steps-users.model';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

const columns: ITableColumnType<ModelUser>[] = [
  { title: 'First Name', dataIndex: 'firstname', key: 'firstname', filter: true },
  { title: 'Last Name', dataIndex: 'lastname', key: 'lastname', filter: true },
  { title: 'Email', dataIndex: 'email', key: 'email', filter: true },
];

const StepsUsers = () => {
  const [data, setData] = useState<ModelUser[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [companyId, setCompanyId] = useState<string>('');
  const [newUser, setNewUser] = useState<ModelUser>(new ModelUser());
  const [selecteds, setSelecteds] = useState<ICheckboxOption[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const adminOption: ICheckboxOption = {
    name: 'ckbAdmin',
    label: 'Admin',
    value: 'ckbAdmin',
    icon: <UserOutlined />,
    disabled: false,
  };

  const addCurrentUserToData = async () => {
    try {
      const profile = await ProfileService.Get();
      if (profile) {
        const currentUser = new ModelUser(
          profile.firstname,
          profile.lastname,
          profile.email,
          true,
          profile.id
        );

        setData([currentUser]);
        setLoading(false);
        return true;
      }
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
    return false;
  };

  useEffect(() => {
    const initializeComponent = async () => {
      try {
        const profile = await ProfileService.Get();
        if (profile && profile.companyId) {
          setCompanyId(profile.companyId);

          await addCurrentUserToData();
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error('Error initializing component:', error);
        setLoading(false);
      }
    };

    initializeComponent();
  }, []);

  const addNew = useCallback(() => setShowForm(true), []);

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const OnChangeSelected = (e: CheckboxChangeEvent, option: ICheckboxOption) => {
    setSelecteds((prevSelecteds) => {
      if (e.target.checked) {
        return [...prevSelecteds, option];
      } else {
        return prevSelecteds.filter((item) => item.value !== option.value);
      }
    });
    setNewUser((prev) => ({ ...prev, admin: e.target.checked }));
  };

  const sendInvite = async () => {
    if (!companyId || !newUser.firstname || !newUser.lastname || !newUser.email) {
      console.error('Missing required fields.');
      return;
    } try {
      const fakeResponse = new ModelUser(
        newUser.firstname,
        newUser.lastname,
        newUser.email,
        newUser.admin,
        `temp-${Date.now()}`
      );

      const currentData = Array.isArray(data) ? data : [];
      const newData = [...currentData, fakeResponse];

      setData(newData);
      setShowForm(false);
      setNewUser(new ModelUser());
      setSelecteds([]);

      try {
        await StepsUsersService.Post(companyId, newUser);
      } catch (postError) {
        console.warn('Could not save user to API, but user was added to UI:', postError);
      }
    } catch (error: unknown) {
      console.error('Error:', error instanceof Error ? error.message : 'Unknown error occurred');
    }
  };

  return (
    <Container>
      <Header title='Users' buttons={[]} />
      {loading ? (
        <div className='p-4 text-center'>Loading users...</div>
      ) : (
        <Table<ModelUser>
          columns={columns}
          dataSource={data}
          rowKey={(record) => record?.id || Math.random().toString()}
        />
      )}

      {showForm && (
        <div className='mt-4 p-4 border rounded bg-gray-100 space-y-6'>
          <div className='grid grid-cols-1 lg:grid-cols-2 mb-5 gap-x-5 gap-y-5'>
            <Input
              title='Firstname'
              type='text'
              id='firstname'
              name='firstname'
              placeholder='Enter the user firstname'
              value={newUser.firstname}
              OnChange={onChangeInput}
            />
            <Input
              title='Lastname'
              type='text'
              id='lastname'
              name='lastname'
              placeholder='Enter the user lastname'
              value={newUser.lastname}
              OnChange={onChangeInput}
            />
            <Input
              title='Email'
              type='email'
              id='email'
              name='email'
              placeholder='Enter the user email'
              value={newUser.email}
              OnChange={onChangeInput}
            />
            <div className='flex items-end justify-start'>
              <CheckBox
                title=''
                options={[adminOption]}
                selected={selecteds}
                onChange={OnChangeSelected}
                layout='horizontal'
              />
            </div>
          </div>
          <div className='flex justify-end mt-4'>
            <Button
              icon={{ value: <SaveOutlined />, size: 'text-lg' }}
              text={{ value: 'Send Invite', weight: 400, size: 'text-md' }}
              color='green'
              tone={500}
              onClick={sendInvite}
            />
          </div>
        </div>
      )}

      <div className='flex items-center justify-end mt-6'>
        {!showForm && (
          <Button
            icon={{ value: <PlusOutlined />, size: 'text-lg' }}
            text={{ value: 'Add New', weight: 400, size: 'text-md' }}
            color='blue'
            tone={500}
            onClick={addNew}
          />
        )}
      </div>
    </Container>
  );
};

export default StepsUsers;
