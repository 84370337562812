import { BaseService } from '../../../../../core/services/base.service';
import { User } from '../../profile/model/user.model';
import { StepsUsers } from '../model/steps-users.model';

export class StepsUsersService {
  static async Get(companyId: string): Promise<User[]> {
    try {
      const response = await BaseService.Get(`/companies/${companyId}/users`);
      if (response && response.content && Array.isArray(response.content)) {
        return response.content;
      } else if (Array.isArray(response)) {
        return response;
      } else {
        console.log('Unexpected response format:', response);
        return [];
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      return [];
    }
  }

  static async Post(companyId: string, user: StepsUsers): Promise<StepsUsers> {
    try {
      const payload = {
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        admin: user.admin,
        id: companyId,
      };
      const response = await BaseService.Post('/public/user-registrations', payload);
      return response;
    } catch (error) {
      console.error('API Error:', error instanceof Error ? error.message : 'Unknown error');
      throw error;
    }
  }
}
