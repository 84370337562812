import { useState, useEffect } from 'react';
import { Table, Header, Container, Button } from '../../../../../core/components';
import { ITableColumnType } from '../../../../../core/interfaces';
import { Clients as ModelClient } from '../model/clients.model';
import { PlusOutlined, MailOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { ClientsService } from '../service/clients.service';
import { ProfileService } from '../../profile/service/profile.service';

const columns: ITableColumnType<ModelClient>[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    filter: true,
    sorter: (a: ModelClient, b: ModelClient) => a.name.localeCompare(b.name),
  },
  {
    title: 'Website',
    dataIndex: 'website',
    key: 'website',
    filter: true,
  },
  {
    title: 'Primary Contact',
    dataIndex: 'primaryContact',
    key: 'primaryContact',
    filter: true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    filter: true,
  },
  {
    title: 'Mobile',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    filter: true,
  },
  {
    title: 'Contact',
    key: 'contact',
    render: (client: ModelClient) => (
      <div className='flex space-x-2 justify-center items-center'>
        <a
          href={`mailto:${client.email}`}
          target='_blank'
          rel='noopener noreferrer'
          className=' bg-blue-500 p-3 w-8 h-8 rounded-md flex items-center justify-center'>
          <MailOutlined className='text-white text-base' />
        </a>
        <a
          href={`tel:${client.phoneNumber}`}
          target='_blank'
          rel='noopener noreferrer'
          className='bg-green-500  p-3 w-8 h-8 rounded-md flex items-center justify-center'>
          <WhatsAppOutlined className='text-white text-base' />
        </a>
      </div>
    ),
  },
  {
    title: 'Active',
    dataIndex: 'active',
    key: 'active',
    filter: true,
    sorter: (a: ModelClient, b: ModelClient) => Number(a.active) - Number(b.active),
    render: (active: boolean) => <span>{active ? 'Yes' : 'No'}</span>,
  },
  {
    title: 'Actions',
    key: 'actions',
    render: () => <div>{/* Action buttons  */}</div>,
  },
];

const Clients = () => {
  const [data, setData] = useState<ModelClient[]>([]);
  const [companyId, setCompanyId] = useState<string>('');
  
    useEffect(() => {
      const getProfile = async () => {
        try {
          const profile = await ProfileService.Get();
          setCompanyId(profile.companyId);
        } catch (error) {
          console.error('Error fetching profile:', error);
        }
      };
      getProfile();
    }, []);
  
    useEffect(() => {
      const getClients = async (companyId: string) => {
        try {
          const clients = await ClientsService.Get(companyId);
          setData(clients);
        } catch (error) {
          console.error('Error fetching clients:', error);
        }
      };
      if (companyId) getClients(companyId);
    }, [companyId]);

  // // const addNew = ()=> {}; navigate to client profile

  return (
    <Container>
      <Header title='Clients' buttons={[]} />

      <Table<ModelClient> columns={columns} dataSource={data} rowKey='id'/>

      <div className='flex items-center justify-end mt-6'>
        <Button
          icon={{ value: <PlusOutlined />, size: 'text-lg' }}
          text={{ value: 'Add New', weight: 400, size: 'text-md' }}
          color='blue'
          tone={500}
          onClick={() => {}} // addNew
        />
      </div>
    </Container>
  );
};

export default Clients;
