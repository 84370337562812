import { JobApplication } from '../../../../../core/models/jobs/job-application.model';
import { JobApplicationService } from '../services/job-application.service';
import { UtilService } from '../../../../../core/services/util.service';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CloseCircleOutlined } from '@ant-design/icons';
import TableComponent from './TableComponent';

const JobApplicationTableById: React.FC = () => {
  const [data, setData] = useState<JobApplication[]>([]);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const GoBack = () => {
    navigate('/private/job');
  };

  useEffect(() => {
    const GetApplications = async () => {
      if (!id) {
        GoBack();
        return;
      } try {
        const result = await JobApplicationService.GetApplicationsById(id!, (error: Error) => {
          if (error.message.includes('400')) {
            UtilService.Alert('Important!', 'info', 'The job id entered does not exist');
            GoBack();
          } else {
            UtilService.Alert('Important!', 'info', 'Internal Server Error');
          }});
        if (result) {
          setData(result);
        }
      } catch (error) {
        UtilService.Alert('Error', 'error', 'Something went wrong while fetching data');
        GoBack();
      }
    };
    GetApplications();
  }, [id]);


  return (
    <TableComponent
      tableType={2}
      state={{
        from: 2,
        jobId: id,
      }}
      cols={{ jobName: false }}
      data={data}
      GoBack={{
        icon: {
          value: <CloseCircleOutlined />,
          size: 'text-lg',
        },
        text: {
          size: 'md',
          value: 'Close',
          weight: 500,
        },
        color: 'red',
        tone: 500,
        onClick: () => GoBack(),
      }}
    />
  );
};

export default JobApplicationTableById;
