import { BaseService } from '../../../../../core/services/base.service';
import { User } from '../../profile/model/user.model';

export class UsersService {
static async Get(companyId: string): Promise<User[]> {
    try {
      const response = await BaseService.Get(`/companies/${companyId}/users`);
      if (response && response.content && Array.isArray(response.content)) {
        return response.content;
      } else if (Array.isArray(response)) {
        return response;
      } else {
        console.log('Unexpected response format:', response);
        return [];
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      return [];
    }
  }
}
