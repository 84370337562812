import { useState, useEffect} from 'react';
import { Table, Header, Container, Button} from '../../../../../core/components';
import { ITableColumnType} from '../../../../../core/interfaces';
import { PlusOutlined, MailOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { ProfileService } from '../../profile/service/profile.service';
import { UsersService } from '../service/users.service';
import { Users as ModelUser } from '../model/users.model';


const columns: ITableColumnType<ModelUser>[] = [
  {
    title: 'First Name',
    dataIndex: 'firstname',
    key: 'firstname',
    filter: true,
    sorter: (a: ModelUser, b: ModelUser) => a.firstname.localeCompare(b.firstname),
  },
  {
    title: 'Last Name',
    dataIndex: 'lastname',
    key: 'lastname',
    filter: true,
    sorter: (a: ModelUser, b: ModelUser) => a.lastname.localeCompare(b.lastname),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    filter: true,
    sorter: (a: ModelUser, b: ModelUser) => a.email.localeCompare(b.email),
  },
  {
    title: 'Mobile',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    filter: true,
  }, {
    title: 'Contact',
    key: 'contact',
    render: (user: ModelUser) => (
      <div className='flex space-x-2 justify-center items-center'>
        <a
          href={`mailto:${user.email}`}
          target='_blank'
          rel='noopener noreferrer'
          className=' bg-blue-500 p-3 w-8 h-8 rounded-md flex items-center justify-center'>
          <MailOutlined className='text-white text-base' />
        </a>
        <a
          href={`tel:${user.phoneNumber}`}
          target='_blank'
          rel='noopener noreferrer'
          className='bg-green-500  p-3 w-8 h-8 rounded-md flex items-center justify-center'>
          <WhatsAppOutlined className='text-white text-base' />
        </a>
      </div>
    ),
  },
  {
    title: 'Active',
    dataIndex: 'active',
    key: 'active',
    filter: true,
    sorter: (a: ModelUser, b: ModelUser) => Number(a.active) - Number(b.active),
    render: (active: boolean) => <span>{active ? 'Yes' : 'No'}</span>,
  },
  {
    title: 'Actions',
    key: 'actions',
    render: () => <div>{/* Action buttons  */}</div>,
  },
];

const Users = () => {
  const [data, setData] = useState<ModelUser[]>([]);
  const [companyId, setCompanyId] = useState<string>('');

  useEffect(() => {
    const getProfile = async () => {
      try {
        const profile = await ProfileService.Get();
        setCompanyId(profile.companyId);
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };
    getProfile();
  }, []);

  useEffect(() => {
    if (!companyId) return;
    const getUsers = async () => {
      try {
        const users = await UsersService.Get(companyId);
        const formattedUsers = users.map(
          (user) => new ModelUser(user.firstname, user.lastname, user.email, user.phoneNumber)
        );
        setData(formattedUsers);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    getUsers();
  }, [companyId]);

  // // const addNew = ()=> {}; navigate to user profile

  return (
    <Container>
      <Header title='Users' buttons={[]} />
      <Table<ModelUser>
        columns={columns}
        dataSource={data}
        rowKey={(record) => record?.id || Math.random().toString()}
      />
      <div className='flex items-center justify-end mt-6'>
        <Button
          icon={{ value: <PlusOutlined />, size: 'text-lg' }}
          text={{ value: 'Add New', weight: 400, size: 'text-md' }}
          color='blue'
          tone={500}
          onClick={() => {}} // addNew
        />
      </div>
    </Container>
  );
};

export default Users;
