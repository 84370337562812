import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PasswordSetup from './components/password-setup/PasswordSetup';
import Login from './components/login/Login';
import RecoveryPassword from './components/recovery-password/RecoveryPassword';

const PublicRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path='/user-registrations' element={<PasswordSetup />} />
      <Route path='/login' element={<Login />} />
      <Route path='/recovery-password' element={<RecoveryPassword />} />
      <Route path='*' element={<Navigate to='/login' replace />} />
    </Routes>
  );
};

export default PublicRoutes;
