import { JobApplicationDto } from "../../../../../core/models/jobs/job-application-dto.model";
import { Pagination } from "../../../../../core/models/sort/pagination.model";
import { JobApplicationService } from "../services/job-application.service";
import React, { useEffect, useState } from "react";
import TableComponent from "./TableComponent";

interface Props {
  dashboard?: boolean;
}

const JobApplicationTable: React.FC<Props> = ({ dashboard = false }) => {
  const [pagination, setPagination] = useState<Pagination>(new Pagination());
  const [dto, setDto] = useState<JobApplicationDto>(new JobApplicationDto());

  useEffect(() => {
    const GetApplications = async () => {
      const result: JobApplicationDto = await JobApplicationService.GetApplications(pagination);
      if (result) {
        setDto(result);
      }
    };
    GetApplications();
  }, [pagination]);

  return (
    <TableComponent
      tableType={1}
      cols={{ jobName: true }}
      state={{
        from: 1,
      }}
      data={dto.content}
      pagination={{
        records: dto.totalElements,
        async OnChange(page, pageSize) {
          setPagination(new Pagination(page, pageSize));
        },
        async OnShowSizeChange(current, size) {
          setPagination(new Pagination(current, size));
        },
      }}
      dashboard={dashboard}
    />
  );
};

export default JobApplicationTable;
