import { AccessTokenPayload } from '../../../../core/models/auth/access-token-payload.model';
import { AccessToken } from '../../../../core/models/auth/access-token.model';
import { UtilService } from '../../../../core/services/util.service';
import { Session } from '../../../../core/models/auth/session.model';
import { useAuthStore } from '../../../../core/store/auth.store';
import { AuthService } from '../../services/auth-login.service';
import React, { useEffect, useRef, useState } from 'react';
import { User } from '../../models/user.model';
import { useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { setAuth, getAuth, resetAuth } = useAuthStore();
  const [user, setUser] = useState<User>(new User());
  const passwordRef = useRef<HTMLInputElement>(null);

  useEffect(() => {}, [navigate, getAuth, resetAuth]);

  const HandleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const Validate = (user: User, callback: () => void) => {
    const errors: string[] = [];

    if (user.username.length === 0) {
      errors.push('You must enter the username.');
    }

    if (user.password.length === 0) {
      errors.push('You must enter the password.');
    }

    if (errors.length > 0) {
      UtilService.Alert('Important!', 'info', errors.join(' </br> '));

      return;
    }

    callback();
  };

const Login = async () => {
    try {
      const item: AccessToken = await AuthService.Login(user);
  
      if (item && item.accessToken) {
        localStorage.setItem('token', item.accessToken);
  
        const payload: AccessTokenPayload = UtilService.DecodeJWT(item.accessToken);
  
        const session: Session = {
          session_state: payload.session_state,
          realm_access: payload.realm_access,
          resource_access: payload.resource_access,
          scope: payload.scope,
          sid: payload.sid,
          email_verified: payload.email_verified,
          name: payload.name,
          preferred_username: payload.preferred_username,
          given_name: payload.given_name,
          family_name: payload.family_name,
          email: payload.email,
        };
  
        setAuth(item, payload, session);
  
        setTimeout(() => {
          navigate('/private/dashboard'); 
        }, 100);
        
      } else {
        console.error("Login failed: No token received");
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  return (
    <div className='flex min-h-screen flex-col justify-center px-6 py-12 lg:px-8'>
      <div className='max-w-[320px] w-full m-auto'>
        <div className='sm:mx-auto w-full'>
          <img
            className='mx-auto h-14 w-auto'
            src={require('../../../../assets/logo.png')}
            alt='Logo'
          />
          <h2 className='mt-4 text-center text-2xl/9 font-bold tracking-tight text-gray-900'>
            Sign in to your account
          </h2>
        </div>

        <div className='mt-10 sm:mx-auto w-full'>
          <div className='space-y-6'>
            <div>
              <label htmlFor='email' className='block text-sm/6 font-medium text-gray-900'>
                Username
              </label>
              <div className='mt-2'>
                <input
                  id='username'
                  name='username'
                  type='text'
                  placeholder='Enter your username...'
                  value={user.username}
                  onChange={HandleInput}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && passwordRef?.current) {
                      passwordRef.current.focus();
                    }
                  }}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6 px-3'
                />
              </div>
            </div>

            <div>
              <div className='flex items-center justify-between'>
                <label htmlFor='password' className='block text-sm/6 font-medium text-gray-900'>
                  Password
                </label>
                <div className='text-sm'>
                  <a
                    href='/#/recovery-password'
                    className='font-semibold text-blue-600 hover:text-blue-500'>
                    Forgot password?
                  </a>
                </div>
              </div>
              <div className='mt-2'>
                <input
                  id='password'
                  name='password'
                  type='password'
                  autoComplete='current-password'
                  required
                  placeholder='Enter your password...'
                  ref={passwordRef}
                  value={user.password}
                  onChange={HandleInput}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      Login();
                    }
                  }}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6 px-3'
                />
              </div>
            </div>

            <div>
              <button
                onClick={() => Validate(user, Login)}
                className='flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
                Sign in
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;