import {
  CloseCircleOutlined,
  DownloadOutlined,
  LinkOutlined,
  MailOutlined,
  PhoneOutlined,
  PlusCircleOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import {
  Header,
  Input,
  Select,
  TextArea,
  Container,
  Dropdown,
  CheckBox,
} from '../../../../../core/components';
import { JobApplicationStatus } from '../../../../../core/models/jobs/job-application-status.model';
import { SelectValue } from '../../../../../core/models/components/select-value.model';
import { JobApplication } from '../../../../../core/models/jobs/job-application.model';
import { JobApplicationService } from '../services/job-application.service';
import { UtilService } from '../../../../../core/services/util.service';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import JobApplicationHistoryTable from './JobApplicationHistoryTable';
import { CategoryService } from '../../../services/category.service';
import { GetSelected } from '../../../util';
import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { ICheckboxOption } from '../../../../../core/interfaces';

const JobApplicationForm: React.FC = () => {
  const [application, setApplication] = useState<JobApplication>(new JobApplication());
  const [status, setStatus] = useState<JobApplicationStatus>(new JobApplicationStatus());
  const [statuses, setStatuses] = useState<string[]>([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();

  const utils = UtilService.GetInstance();

  const emailNotifiable: ICheckboxOption = {
    name: 'ckbEmail',
    label: 'Email',
    value: 'ckbEmail',
    icon: <MailOutlined />,
    disabled: true,
  };
  const whatsappNotifiable = {
    name: 'ckbWhatsApp',
    label: 'WhatsApp',
    value: 'ckbWhatsApp',
    icon: <PhoneOutlined />,
    disabled: true,
  };

  const [selecteds, setSelecteds] = useState<ICheckboxOption[]>([emailNotifiable]);
  const options = [emailNotifiable, whatsappNotifiable];

  const GoBack = () => {
    navigate('/private/job-applications');
  };

  const Close = () => {
    navigate(GetSelected(location));
  };

  useEffect(() => {
    if (!id) {
      GoBack();
    }

    setIsOpen(false);

    const Get = async () => {
      utils.transitionSubject.next(true);

      const vApplication = await GetApplications();

      if (vApplication) {
        const [categories, statuses] = await Promise.all([
          CategoryService.Get(),
          JobApplicationService.GetStatuses(id!),
        ]);

        setApplication(vApplication);
        setStatuses(statuses);

        const updateds = [emailNotifiable];

        if (vApplication.whatsappNotifiable) {
          updateds.push(whatsappNotifiable);
        }

        setSelecteds(updateds);

        utils.transitionSubject.next(false);
      }
    };

    Get();
  }, [id]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const GetApplications = async () => {
    return await JobApplicationService.GetById(id!, (error: Error) => {
      if (error.message.includes('400')) {
        UtilService.Alert('Important!', 'info', 'The job applicattion entered does not exist');

        Close();
      } else {
        UtilService.Alert('Important!', 'info', 'Internal Server Error');
      }
    });
  };

  const GetFile = async () => {
    const blob = await JobApplicationService.GetFile(application.id, application.file.id);

    const urlBlob = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = urlBlob;

    a.download = application.file.name;
    document.body.appendChild(a);
    a.click();

    a.remove();
    window.URL.revokeObjectURL(urlBlob);
  };

  const Statuses = (): SelectValue[] => {
    return [...statuses.map((s) => new SelectValue(s.replace(/_/g, ' '), s))];
  };

  const Validate = async (status: JobApplicationStatus, Callback: () => Promise<void>) => {
    if (status.statusName.length === 0) {
      UtilService.Alert('Important!', 'info', 'You need to select a state.', '400px');

      return;
    }

    if (status.statusName === 'CUSTOM' && status.note.length === 0) {
      UtilService.Alert('Important!', 'info', 'You need to enter an note.', '400px');

      return;
    }

    await Callback();
  };

  const ValidateDropdown = (status: JobApplicationStatus) => {
    if (
      status.statusName.length === 0 ||
      (status.statusName === 'CUSTOM' && status.note.length === 0)
    ) {
      return true;
    }

    return false;
  };

  const Add = async (notifiable: boolean): Promise<void> => {
    await Validate(status, async () => {
      await JobApplicationService.Add(application.id, status, notifiable);

      setIsOpen(false);

      UtilService.Alert('Success!', 'success', 'The Status has been added.');

      utils.transitionSubject.next(true);

      const vApplication = await GetApplications();

      utils.transitionSubject.next(false);

      setApplication(vApplication);
    });
  };

  const OnChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    setStatus((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  };

  const OnChangeSelect = (name: string, value: string) => {
    setStatus((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  };

  return (
    <div className='mx-auto'>
      <div>
        <Container>
          <Header
            title='Job Information'
            buttons={[
              {
                icon: {
                  value: <CloseCircleOutlined />,
                  size: 'text-lg',
                },
                text: {
                  size: 'md',
                  value: 'Close',
                  weight: 500,
                },
                color: 'red',
                tone: 500,
                onClick: () => Close(),
              },
            ]}
          />

          <div className='grid grid-cols-1 lg:grid-cols-2 mb-5 gap-x-5 gap-y-5'>
            <Input
              title='Category'
              placeholder='Select an option'
              name='category'
              value={application.job.category}
              disabled={true}
              type='text'
            />

            <Input
              title='Sub Category'
              placeholder='Select an option'
              name='subCategory'
              value={application.job.subCategory}
              disabled={true}
              type='text'
            />
          </div>

          <div className='grid grid-cols-1 lg:grid-cols-2 mb-5 gap-x-5 gap-y-5'>
            <Input
              title='Job Name'
              placeholder='Enter the Job Name...'
              type='string'
              id='name'
              name='name'
              value={application.job.name}
              inputClass=''
              OnChange={() => {}}
              disabled={true}
            />

            <Input
              title='Location'
              placeholder='Select an option'
              name='location'
              value={application.job.location.replace('-', ' ')}
              disabled={true}
              type='text'
            />
          </div>
        </Container>

        <Container>
          <Header title='Contact Information' buttons={[]} />

          <div className='grid grid-cols-1 lg:grid-cols-2 mb-5 gap-x-5 gap-y-5'>
            <Input title='Name' type='string' value={application.fullname} disabled={true} />

            <Input
              title='Resume'
              type='string'
              value={application.file.name}
              disabled={true}
              buttons={[
                {
                  title: 'Download',
                  icon: { size: 'text-base', value: <DownloadOutlined /> },
                  color: 'blue',
                  tone: 500,
                  onClick: () => {
                    GetFile();
                  },
                },
              ]}
            />
          </div>

          <div className='grid grid-cols-1 lg:grid-cols-3 mb-5 gap-x-5 gap-y-5'>
            <Input
              title='Mobile'
              type='string'
              value={application.phoneNumber}
              disabled={true}
              buttons={[
                {
                  title: 'WhatsApp',
                  icon: { size: 'text-base', value: <WhatsAppOutlined /> },
                  color: 'green',
                  tone: 500,
                  onClick: () => {
                    const formattedNumber = application.phoneNumber.replace(/\D/g, '');

                    window.open(`https://wa.me/${formattedNumber}`, '_blank');
                  },
                },
              ]}
            />

            <Input
              title='Email'
              type='string'
              value={application.email}
              disabled={true}
              buttons={[
                {
                  title: 'Mail',
                  icon: { size: 'text-base', value: <MailOutlined /> },
                  color: 'blue',
                  tone: 500,
                  onClick: () =>
                    window.open(`mailto:${application.email}`, '_blank') ||
                    (window.location.href = `mailto:${application.email}`),
                },
              ]}
            />

            <Input
              title='Profile URL'
              type='string'
              value={application.websiteURL}
              disabled={true}
              buttons={[
                {
                  title: 'Profile URL',
                  icon: { size: 'text-base', value: <LinkOutlined /> },
                  color: 'blue',
                  tone: 500,
                  onClick: () => {
                    window.open(application.websiteURL, '_blank');
                  },
                },
              ]}
            />
          </div>

          <div className='grid grid-cols-1 mb-5 gap-x-5 gap-y-5'>
            <div className='flex items-center justify-start mt-0'>
              <CheckBox
                title='Be notified On'
                options={options}
                selected={selecteds}
                onChange={() => {}}
                layout='horizontal'
              />
            </div>
          </div>
        </Container>

        <Container>
          <Header
            title='Job Application History'
            buttons={[
              {
                icon: {
                  value: <PlusCircleOutlined />,
                  size: 'text-lg',
                },
                text: {
                  size: 'md',
                  value: 'Add Note',
                  weight: 400,
                },
                color: 'blue',
                tone: 500,
                onClick: () => setIsOpen(true),
              },
            ]}
          />

          <JobApplicationHistoryTable
            data={application.jobApplicationStatuses}
            ShowModal={(item) => {
              console.log(item);

              setStatus(item);
              setIsOpen(true);
            }}
          />
        </Container>
      </div>

      <ReactModal
        isOpen={isOpen}
        onRequestClose={() => {}}
        className='bg-white rounded-lg p-6 max-w-lg mx-auto shadow-lg w-full scrollbar-hide'
        overlayClassName='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1000]'
        ariaHideApp={false}>
        <Header
          title='Send Notes'
          buttons={[
            {
              icon: {
                value: <CloseCircleOutlined />,
                size: 'text-lg',
              },
              text: {
                size: 'md',
                value: 'Close',
                weight: 500,
              },
              color: 'red',
              tone: 500,
              onClick: () => {
                setStatus(new JobApplicationStatus());
                setIsOpen(false);
              },
            },
          ]}
        />

        <div>
          <div className='grid grid-cols-1 mb-5 gap-x-5 gap-y-5'>
            {status.id && (
              <Input
                title='Date'
                type='date'
                value={new Date(status.createdDate).toISOString().split('T')[0]}
                disabled={true}
              />
            )}

            <Select
              title='Status'
              placeholder='Select an option'
              options={Statuses()}
              name='statusName'
              value={status.statusName}
              simple={true}
              simplestate={status.id.length === 0}
              onChange={OnChangeSelect}
              disabled={status.id.length != 0}
              validation={{
                Validator: () => status.statusName === '',
              }}
            />

            <TextArea
              title='Notes'
              placeholder='Enter the Notes...'
              value={status.note}
              name='note'
              rows={20}
              maxHeight='max-h-56'
              simple={true}
              simplestate={status.id.length === 0}
              onChange={OnChangeTextArea}
              disabled={status.id.length != 0}
              validation={{
                Validator: () => status.note === '',
              }}
            />
          </div>
        </div>

        {!status.id && (
          <div className='flex items-center justify-end'>
            <Dropdown
              text={{ value: 'Actions', weight: 400, size: 'text-md' }}
              color='blue'
              tone={500}
              items={[
                {
                  label: 'Save',
                  visible: !status.id,
                  disabled: ValidateDropdown(status),
                  onClick: async () => {
                    await Add(false);
                  },
                },
                {
                  label: 'Save And Notify',
                  visible: !status.id,
                  disabled: ValidateDropdown(status),
                  onClick: async () => {
                    await Add(true);
                  },
                },
              ]}
              disabled={false}
            />
          </div>
        )}
      </ReactModal>
    </div>
  );
};

export default JobApplicationForm;
