import CompanyProfile from './CompanyProfile';
import Users from './Users';
import Clients from './Clients';

const Company = () => {
  return (
    <>
      <CompanyProfile />
      <Clients />
      <Users />
    </>
  );
};

export default Company;
