import { Container, Header, Input, Button } from '../../../../../core/components';
import { CompanyService } from '../../company/service/company-profile.service';
import { UtilService } from '../../../../../core/services/util.service';
import { useEffect, useState } from 'react';
import { Company } from '../../company/model/company-profile.model';
import { SaveOutlined } from '@ant-design/icons';
import { ProfileService } from '../../profile/service/profile.service';
import { User } from '../../profile/model/user.model';

interface StepsCompanyProfileProps {
  onCompanyUpdated?: (companyId: string) => void;
}

const StepsCompanyProfile: React.FC<StepsCompanyProfileProps> = ({ onCompanyUpdated }) => {
  const [company, setCompany] = useState<Company>(new Company());
  const [companyInitialState, setCompanyInitialState] = useState<Company>(new Company());
  const [companyId, setCompanyId] = useState<string | null>(null);
  const [profile, setProfile] = useState<User | null>(null);

  useEffect(() => {
    const getProfile = async () => {
      try {
        const profile = await ProfileService.Get();
        setProfile(profile);
        setCompanyId(profile.companyId || null);
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };
    getProfile();
  }, []);

  useEffect(() => {
    if (companyId) {
      const Get = async () => {
        try {
          const companyData = await CompanyService.Get(companyId);
          setCompany(companyData || new Company());
          setCompanyInitialState(companyData || new Company());
        } catch (error: unknown) {
          console.error('Error fetching company data:', error);
          UtilService.Alert('Error', 'error', 'Failed to load company data.');
        }
      };
      Get();
    }
  }, [companyId]);

  const OnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCompany((prevCompany) => ({
      ...prevCompany,
      [name]: value,
    }));
  };

  const Validate = (company: Company) => {
    const errors: string[] = [];
    if (!company.name.trim()) {
      errors.push('You must enter the company name.');
    }
    if (!company.website.trim()) {
      errors.push('You must enter the company website.');
    }
    return errors;
  };

  const Post = async () => {
    const errors = Validate(company);

    if (errors.length > 0) {
      UtilService.Alert('Important!', 'info', errors.join(' </br> '));
      return;
    }

    try {
      let updatedCompanyId: string;

      if (company.id) {
        await CompanyService.Patch(company.id, company);
        updatedCompanyId = company.id;
        UtilService.Alert('Success!', 'success', 'The company profile has been updated.');
      } else {
        const newCompany = await CompanyService.Post(company);
        updatedCompanyId = newCompany.id;

        if (profile) {
          await ProfileService.Patch({
            ...profile,
            companyId: updatedCompanyId,
            profileSet: false,
          });
        }

        UtilService.Alert('Success!', 'success', 'The company has been created.');
      }

      if (onCompanyUpdated) {
        onCompanyUpdated(updatedCompanyId);
      }

      const updatedCompany = await CompanyService.Get(updatedCompanyId);
      setCompany(updatedCompany);
      setCompanyInitialState(updatedCompany);
      setCompanyId(updatedCompanyId);

      const updatedProfile = await ProfileService.Get();
      setProfile(updatedProfile);
    } catch (error) {
      console.error('Error updating/creating company profile:', error);
      UtilService.Alert('Error', 'error', 'Failed to update or create company profile.');
    }
  };

  const isDataUnchanged = JSON.stringify(company) === JSON.stringify(companyInitialState);

  return (
    <Container>
      <Header title='Company Profile' buttons={[]} />
      <div className='flex flex-col space-y-6'>
        <div className='grid grid-cols-1 lg:grid-cols-2 mb-5 gap-x-5 gap-y-5'>
          <Input
            title='Name'
            placeholder='Enter the company name...'
            type='text'
            id='companyName'
            name='name'
            value={company.name}
            OnChange={OnChangeInput}
            validation={{ Validator: () => company.name === '' }}
          />
          <Input
            title='Website'
            placeholder='Enter the company website...'
            type='text'
            id='website'
            name='website'
            value={company.website}
            OnChange={OnChangeInput}
            validation={{ Validator: () => company.website === '' }}
          />
        </div>
        <div className='flex items-center justify-end mt-6'>
          <Button
            icon={{ value: <SaveOutlined />, size: 'text-lg' }}
            text={{ value: 'Save', weight: 400, size: 'text-md' }}
            color='blue'
            tone={500}
            disabled={isDataUnchanged || Validate(company).length !== 0}
            onClick={Post}
          />
        </div>
      </div>
    </Container>
  );
};

export default StepsCompanyProfile;
