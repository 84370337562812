import { BaseService } from '../../../../../core/services/base.service';
import { StepsClients } from '../model/steps-clients.model';

export class StepsClientsService {
  static async Get(companyId: string): Promise<StepsClients[]> {
    return await BaseService.Get(`/companies/${companyId}/clients`);
  }

  static async Post(companyId: string, client: StepsClients): Promise<StepsClients> {
    return await BaseService.Post(`/companies/${companyId}/clients`, {
      name: client.name,
      website: client.website,
      active: client.active,
    });
  }
}
